// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { readOnly } from '@ember/object/computed';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';

import { Capacitor } from '@capacitor/core';
import Ember from 'ember';
import pick from 'lodash.pick';
import throttle from 'lodash.throttle';
import mixpanel, { Mixpanel } from 'mixpanel-browser';

import ENV from 'mobile-web/config/environment';
import { computedSession } from 'mobile-web/lib/computed';
import dayjs from 'mobile-web/lib/dayjs';
import ServeTrackingConsent from 'mobile-web/lib/plugins/serve-tracking-consent';
import { errResult, isOk, okResult, Result } from 'mobile-web/lib/result';
import { noop } from 'mobile-web/lib/utilities/_';
import { isSome } from 'mobile-web/lib/utilities/is-some';
import BasketModel from 'mobile-web/models/basket';
import BootstrapData, { ChannelData, MixpanelBucket } from 'mobile-web/models/bootstrap-data';
import { EcommerceOrderModel } from 'mobile-web/models/order';
import Vendor from 'mobile-web/models/vendor';
import { routeToPageName } from 'mobile-web/router';
import BasketService from 'mobile-web/services/basket';
import BootstrapService from 'mobile-web/services/bootstrap';
import ChannelService from 'mobile-web/services/channel';
import DeviceService from 'mobile-web/services/device';
import ErrorService from 'mobile-web/services/error';
import GlobalDataService from 'mobile-web/services/global-data';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';

import FeaturesService, { AB_TEST_FLAG_PREFIX } from './features';
import OnPremiseService from './on-premise';
import SecurityService from './security';

export enum AnalyticsEvents {
  AccountsDeleteCreditCardConfirm = 'Accounts Delete Credit Card Confirm',
  AccountsDeleteCreditCardRequest = 'Accounts Delete Credit Card Request',
  AccountsMakeCardDefault = 'Accounts Make Card Default',
  AddCreditCard = 'Added Credit Card',
  AddGiftCard = 'Added Gift Card',
  AddToCart = 'Add to Cart',
  AddToCartIntent = 'Add to Cart Intent',
  ApplePayHybridButtonTapped = 'Apple Pay on Hybrid Tapped',
  ApplePayHybridTokenGenerated = 'Apple Pay Hybrid Token Generated',
  AppOpen = 'App Open',
  AddUpsellIntent = 'Add Upsell Intent',
  AnsweredChangeLocation = 'Answered Change Location',
  AskedForParkingLocation = 'Asked For Parking Location',
  AutoDetectLocation = 'Auto-Detect Location',
  BeginOrder = 'Begin Order',
  BreadcrumbTapped = 'Breadcrumb Tapped',
  CancelGroupOrder = 'Cancel Group Order',
  ChangeHandoffModeHomePage = 'Change Handoff Mode - Home Page',
  ChangeOrderCriteria = 'Change Order Criteria',
  ChangeOrderCriteriaCanceled = 'Change Order Criteria Canceled',
  ChangeOrderCriteriaIntent = 'Change Order Criteria Intent',
  ChangeStoreLocation = 'Change Store Location',
  ChangeStoreLocationResults = 'Change Store Location - Location Results',
  ChangeStoreLocationSearchNearby = 'Change Store Location - Search Nearby',
  ChangeWhenSelection = 'Change When Selection',
  CheckoutDeleteCreditCardConfirm = 'Checkout Delete Credit Card Confirm',
  CheckoutDeleteCreditCardRequest = 'Checkout Delete Credit Card Request',
  CheckoutProceedAsGuest = 'Checkout Proceed as Guest',
  CheckoutSignInOrCreateAnAccount = 'Checkout Sign In or Create an Account',
  CloseCartAction = 'Close Cart Action',
  ContactUs = 'Contact Us',
  CreateAccount = 'Create Account Submit',
  DeleteAccountClicked = 'Delete Account Clicked',
  DeleteAccountConfirmed = 'Delete Account Confirmed',
  DeleteAccountRequested = 'Delete Account Requested',
  DeleteAccountSuccess = 'Delete Account Success',
  DisabledButtonTapped = 'Disabled Button Tapped',
  DownloadOnAppStore = 'Download on App Store',
  EditContactInfo = 'Edit Contact Info',
  EditCreditCard = 'Edit Credit Card',
  EditLocationIntent = 'Edit Location Intent',
  EditOrderIntent = 'Edit Order Intent',
  EnteredLocation = 'Entered Location',
  ErrorShown = 'Error Shown',
  FirstAppOpen = 'First App Open',
  GetDirections = 'Get Directions',
  GooglePayHybridButtonTapped = 'Google Pay on Hybrid Tapped',
  GooglePayHybridTokenGenerated = 'Google Pay Hybrid Token Generated',
  GroupOrderStarted = 'Group Order Started',
  GroupOrderHostProceededParticipantsInProgress = 'Group Order - Host proceeded with participants In Progress',
  GroupOrderInviteOthersButton = 'Group Order - opened Invite Others modal',
  GroupOrderInviteOthersCopyLink = 'Group Order - copied URL to join group order',
  GroupOrderInviteOthersShareLink = 'Group Order - shared link via native function',
  GroupOrderJoinGroupOrder = 'Group Order - Participant Joined',
  GuestOptedIntoSaveCreditCard = 'Guest Opted Into Save Credit Card',
  HelpCenterClicked = 'Help Center Clicked',
  IntentToAddCreditCard = 'Intent to Add Credit Card',
  IntentToAddGiftCard = 'Intent to Add Gift Card',
  KeyboardShown = 'Keyboard Shown',
  LandedOnVendorMenu = 'Landed on Vendor Menu',
  LeaveGroupOrder = 'Participant: Leave group order',
  LinkLoyaltyAccountClicked = 'Join Loyalty Rewards Program to Link to Olo Auth Clicked',
  LinkLoyaltyAccountCompleted = 'Join Loyalty Rewards Program to Link to Olo Auth Completed',
  LinkLoyaltyAccountError = 'Join Loyalty Rewards Program to Link to Olo Auth Error',
  LinkOloAuthAccountClicked = 'Link to Olo Auth to Loyalty Account Clicked',
  LinkOloAuthAccountCompleted = 'Link to Olo Auth to Loyalty Account Completed',
  LinkOloAuthAccountError = 'Link to Olo Auth to Loyalty Account Error',
  LoadDispatchMapData = 'Load Dispatch Map Data',
  LoadedCheckoutPaymentInfo = 'Loaded Checkout Payment Info',
  LocalStorageAutoSkipToMenu = 'Local Storage Auto Skip to Menu',
  LocationCloudflareFound = 'Location Cloudflare Found?',
  LocationDetection = 'Location Detection',
  ExternalSignIn = 'External Sign In Submit',
  ManualLocationSelectionSkipped = 'Manual Location Selection Skipped',
  MenuSearchBoxIntent = 'Menu Search Box Intent',
  MenuSearchBoxSelectedProduct = 'Menu Search Box Selected Product',
  MenuSearchBoxTyped = 'Menu Search Box Typed',
  MenuTypeFilter = 'Menu Type Filter',
  ModifierSelectionIntent = 'Modifier Selection Intent',
  NoLocationsFound = 'No Locations Found',
  OloAuthCheckoutAccountSettingsClicked = 'Olo Auth Checkout Account Settings Clicked',
  OloAuthCheckoutViewTooltip = 'Olo Auth Checkout View Tooltip',
  OloAuthOptInChecked = 'Olo Auth Opt-In Checked',
  OloAuthOverlayClosed = 'Olo Auth Overlay Closed',
  OloAuthOverlayOpened = 'Olo Auth Overlay Opened',
  OloAuthOverlaySignIn = 'Olo Auth Overlay Sign In',
  OloAuthOverlayResendCode = 'Olo Auth Overlay Resend Code',
  OloAuthOverlayToggleCodeSendMethod = 'Olo Auth Overlay Toggle Code Send Method',
  OloAuthPrivacyPolicyClicked = 'Olo Auth Privacy Policy Clicked',
  OloAuthSignInWithOloButtonClicked = 'Sign In with Olo Button Clicked',
  OloAuthTermsOfServiceClicked = 'Olo Auth Terms of Service Clicked',
  OloAuthLearnMoreLinkClicked = 'Olo Auth Learn More Link Clicked',
  OpenCheckCheckClosed = 'Open Check - Check Closed',
  OpenCheckFinishPay = 'Open Check - Finish & Pay',
  OpenCheckPlaceOrderAddToCheck = 'Open Check - Place Order & Add to Check',
  OpenCheckRoundStarted = 'Open Check - Round Started',
  OpenCheckUserInfo = 'Open Check - User Info',
  OrderError = 'Order Error',
  OrderMore = 'Order More',
  OrderPlaced = 'Order Placed',
  OrderItAgainDismiss = 'Order It Again Dismiss',
  OrderItAgainUnavailableItem = 'Order It Again Unavailable Disclaimer Shown',
  OrderItAgainAddToCart = 'Order It Again Add To Cart',
  OrderItAgainAddAndCheckout = 'Order It Again And Checkout',
  OrderItAgainModalCancelled = 'Order It Again Modal Cancelled',
  OrderItAgainModalConfirm = 'Order It Again Modal Confirmed',
  OrderItAgainInExperiment = 'Order It Again',
  PayAtTableCheckoutPage = 'Pay At Table - Checkout Page',
  PayAtTableClickedCreateAccount = 'Pay At Table - Clicked Create Account',
  PayAtTableClickedFindCheck = 'Pay At Table - Clicked Find Check',
  PayAtTableFindCheckPage = 'Pay At Table - Find Check Page',
  PayAtTableOrderPaidPage = 'Pay At Table - Order Paid Page',
  PayAtTableSelectCheckPage = 'Pay At Table - Select Check Page',
  PayAtTableSelectCheckClick = 'Pay at Table - Select Check Click',
  ProceedToCheckout = 'Proceed to Checkout',
  RemoveFromCart = 'Remove from Cart',
  RemoveSelectedCard = 'Remove Selected Card',
  ReorderNow = 'Reorder Now',
  SaveCreditCardEdits = 'Save Credit Card Edits',
  SearchForLocations = 'Search For Locations',
  SelectCardOnFile = 'Select Card on File',
  SentimentSurveyBannerClicked = 'Sentiment Survey Banner Clicked',
  SentimentSurveyCompleted = 'Sentiment Survey Completed',
  SentimentSurveyDismissed = 'Sentiment Survey Dismissed',
  SentimentSurveyShown = 'Sentiment Survey Shown',
  SentimentSurveyThumbsDown = 'Sentiment Survey Thumbs Down',
  SentimentSurveyThumbsUp = 'Sentiment Survey Thumbs Up',
  SideMenuPreviousOrders = 'Side Menu - Previous Orders',
  SignIn = 'Sign In Submit',
  SingleUseAddButtonTapped = 'Single Use Add Button Tapped',
  SplitCheckClearSplitConfirmed = 'Split Check - Clear Split Confirmed',
  SplitCheckClearSplitClosed = 'Split Check - Clear Split Closed',
  SplitCheckClearSplitOpened = 'Split Check - Clear Split Opened',
  SplitCheckModalDismissed = 'Split Check - Modal Dismissed',
  SplitCheckOpenModal = 'Split Check - Open Modal',
  SplitCheckSubmitChoice = 'Split Check - Submit Choice',
  StartGroupOrder = 'Start Group Order',
  StickyNavCategoryClick = 'Sticky Nav Category Click',
  ThirdPartySignIn = 'Third Party Sign In',
  UpdateContactInfo = 'Update Contact Info',
  UserFiredOrderEvent = 'User Fired Order Event',
  VendorPageOrdersButton = 'Vendor Page - Orders Button',
  ViewAccountSupport = 'View Account Support',
  ViewAllLocations = 'View All Locations?',
  ViewCartAction = 'View Cart Action',
  ViewDeleteAccount = 'View Delete Account',
  ViewMenu = 'View Menu',
  ViewPage = 'View Page',
  ViewProductCustomization = 'View Product Customization',
  ViewRegionsLocations = "View Region's Locations?",
  ViewCreateAccount = 'View Create Account',
  ViewGuestCheckoutForm = 'View Guest Checkout Form',
  ViewMapClicked = 'View Map Link Actioned',
  ViewUpsellsAction = 'View Upsells Action',
  ViewSignInForm = 'View Sign In Form',
  RemoveTimemode = 'RemoveTimemode',
  CvvRevalidationFocus = 'CVV Revalidation clicked',
  CvvRevalidationShown = 'CVV Revalidation Field Shown',
  CvvRevalidationError = 'CVV Revalidation Missing Error',
  ApiRequestChallenged = 'Api Request Challenged',
}

export type EventName = AnalyticsEvents | `LD ${string}`;
export type AddToCartMethod =
  | 'Product Customize'
  | 'Quick Add'
  | 'Upsell'
  | 'Zero Modifiers Product Modal';

export enum AnalyticsProperties {
  ABTestFlags = 'A/B Test Flags',
  ActionType = 'Action Type',
  AddToCartMethod = 'Add to Cart Method',
  AddToCartType = 'Add to Cart Type',
  AppStore = 'AppStore',
  AppVersion = 'App Version',
  AnsweredChangeLocationSelection = 'Answered Change Location Selection',
  AnsweredChangeLocationDialogueText = 'Answered Change Location Location Dialogue Text',
  AnsweredChangeLocationTemplateString = 'Answered Change Location Template String',
  BasketDeliveryCharge = 'Basket Delivery Charge',
  BasketDiscount = 'Basket Discount',
  BasketHandoffMode = 'Basket Handoff Mode',
  BasketHasDiscount = 'Basket Has Discount',
  BasketHasSingleUseProducts = 'Basket Has Single Use Products',
  BasketID = 'Basket ID',
  BasketLeadTimeEstimate = 'Basket Lead Time Estimate',
  BasketOnPremiseExperience = 'Basket On Premise Experience',
  BasketOnPremiseTable = 'Basket On Premise Table',
  BasketProductQuantity = 'Basket Product Quantity',
  BasketProducts = 'Basket Products',
  BasketSubtotal = 'Basket Subtotal',
  BasketTax = 'Basket Tax',
  BasketTimeWantedMode = 'Basket Time Wanted Mode',
  BasketTotal = 'Basket Total',
  ExternalLoginType = 'External Login Type',
  Label = 'Label',
  LinkLoyaltyTarget = 'Loyalty Linking Target',
  LinkLoyaltySource = 'Loyalty Linking Source',
  LocationSearchIndex = 'Location Search Index',
  CardType = 'Card Type',
  CardLastFour = 'Card Last Four',
  CategoryIndex = 'Category Index',
  CategoryIsWithinViewMore = 'Category Is Within View More?',
  CategoryName = 'Category Name',
  Channel = 'Channel',
  ChannelId = 'Channel ID',
  Checked = 'Checked?',
  CheckId = 'Check ID',
  CheckNumber = 'Check Number',
  ChangeLocationShown = 'Change Location Shown?',
  ClientPlatform = 'Client Platform',
  ClosestLocationDistance = 'Closest Location Distance',
  CloudflareGeolocationSuccess = 'Cloudflare Geolocation Success',
  CloudflareHeader = 'Cloudflare Header',
  ConsecutiveLoads = 'Consecutive Loads',
  CouponCode = 'Coupon Code',
  CodeSentVia = 'Code Sent Via',
  CreateOloAuthAccount = 'Create Olo Auth Account?',
  RecommendationModelVersion = 'Recommendation Model Version',
  CurrentUserTime = 'Current User Time',
  CurrentUserTimeZone = 'Current User Time Zone',
  DaysSinceLastOrder = 'Days Since Last Order',
  EditedOrderFromCheckout = 'Edited Order From Checkout',
  ElapsedSeconds = 'Elapsed Seconds',
  ErrorDescription = 'Error Description',
  ErrorDetails = 'Error Details',
  ErrorType = 'Error Type',
  ExternalEditUrl = 'External Edit URL',
  ExternalNavigation = 'External Navigation',
  FilterName = 'Filter Name',
  FlagValue = 'Flag Value',
  FlagUserKey = 'Flag User Key',
  FlagEvalutationReason = 'Flag Evaluation Reason',
  FurthestLocationDistance = 'Furthest Location Distance',
  HandoffDefaultSlug = 'Handoff Default Slug',
  HandoffQueryParameter = 'Handoff Query Parameter',
  HandoffSelection = 'Handoff Selection',
  HandoffSelectionAvailability = 'Handoff Selection Availability',
  HandoffSelectionList = 'Handoff Selection List',
  HandoffSelectionSlug = 'Handoff Selection Slug',
  HandoffSelectionSlugList = 'Handoff Selection Slug List',
  HasBasket = 'Has Basket?',
  HasCategoryImages = 'Has Category Images?',
  HasDefaultCreditCard = 'Has Default Credit Card',
  HasDefaultGiftCard = 'Has Default Gift Card',
  HasDefaultsForRequiredModifiers = 'Has Defaults For Required Modifiers?',
  HasModifierImage = 'Has Modifier Image',
  HasOloAuth = 'Has Olo Auth?',
  HasUnavailableProducts = 'Has Unavailable Products?',
  HasProductImages = 'Has Product Images?',
  HasRequiredModifiers = 'Has Required Modifiers?',
  HasTableNumber = 'Has Table Number?',
  HasVisibleCalories = 'Has Visible Calories?',
  HasVisiblePrice = 'Has Visible Price?',
  IncludeUtensils = 'Include Utensils',
  IsApplePayButtonPresent = 'Is Apple Pay Button Present',
  IsARequiredModifier = 'Is A Required Modifier',
  IsDefault = 'Is Default',
  IsDefaultedForRequiredModifier = 'Is Defaulted For Required Modifier',
  IsDynamicPricingEnabled = 'Is Dynamic Pricing Enabled?',
  IsFeatured = 'Is Featured?',
  IsGooglePayButtonPresent = 'Is Google Pay Button Present',
  IsGuest = 'Is Guest?',
  IsHybrid = 'Is Hybrid?',
  IsOloChannelLogin = 'Is Olo Channel Login?',
  IsOloAuth = 'Is Olo Auth?',
  IsOloPay = 'Is Olo Pay?',
  IsPayAtTable = 'Is Pay At Table?',
  IsPlaceholderEmail = 'Is Placeholder Email?',
  IsRecentItem = 'Is Recent Item?',
  IsRecentItemPersonalized = 'Is Recent Item Personalized?',
  IsReopen = 'Is Reopen?',
  IsSingleUse = 'Is Single Use?',
  IsSmartUpsell = 'Is Smart Upsell?',
  IsSSO = 'Is SSO?',
  IsSuccessful = 'Is Successful?',
  IsTranslated = 'Is Translated?',
  ItemsReturnedInTheSearch = 'Items Returned In The Search',
  ItemSelected = 'Item Selected',
  KeyboardIdentifier = 'Keyboard Identifier',
  LinkLocation = 'Link Location',
  ListIndex = 'List Index',
  Location = 'Location',
  LocationSource = 'Location Source',
  LoginProvider = 'Login Provider',
  LoyaltyMembershipID = 'Loyalty Membership ID',
  LoyaltyProvider = 'Loyalty Provider',
  LoyaltyRedemption = 'Loyalty Redemption?',
  LoyaltyRewardDiscountAmount = 'Loyalty Reward Discount Amount',
  LoyaltyRewardID = 'Loyalty Reward ID',
  ManualLocationSelectionSkipped = 'Manual Location Selection Skipped?',
  ModifierName = 'Modifier Name',
  ModifierGroup = 'Modifier Group',
  ModifierPrice = 'Modifier Price',
  NewText = 'New Text',
  NumberOfUnavailableItems = 'Number of Unavailable Items',
  NumberOfPaymentTypesUsed = 'Number of Payment Types Used',
  NumberOfLocations = 'Number of Locations',
  NumberOfLocationsPerPage = 'Locations Per Page',
  NumberOfOpenChecks = 'Number of Open Checks',
  OnPremiseQueryParameter = 'On-Premise Query Parameter',
  OpenCheckRoundsOrdered = 'Open Check Rounds Ordered',
  OrderEvent = 'Order Event',
  OrderItAgainCohort = 'Order It Again Cohort',
  PaymentType = 'Payment Type',
  QuickAddSupported = 'Quick Add Supported?',
  SaveCreditCard = 'Save Credit Card',
  SetDefault = 'Set Default',
  TableNumber = 'Table Number',
  TextEntered = 'Text Entered',
  TicketSubtotal = 'Ticket Subtotal',
  ScheduledTimeWanted = 'Scheduled Time Wanted',
  SplitAmount = 'Split Amount',
  SplitCheck = 'Split Check?',
  SplitCheckEditingExistingSplitCheck = 'Split Check - Editing Split Check',
  SplitEqually = 'Split Equally',
  SplitItems = 'Split Items',
  SplitMethod = 'Split Method',
  SplitCheckHasSplitPayment = 'Split Check - Has Split Payment?',
  VendorDistances = 'Vendor Distances',
  VendorIds = 'Vendor Ids',
  WhenSelection = 'When Selection',
  WhenSelectionList = 'When Selection List',
  WhenSelectionSlug = 'When Selection Slug',
  WhenSelectionSlugList = 'When Selection Slug List',
  /**
   * @deprecated
   * only used for AnalyticsEvents.LoadDispatchMapData
   */
  OrderId = 'Order Id',
  OrderID = 'Order ID',
  PageName = 'Page Name',
  PageTitle = 'Page Title',
  PaymentTypes = 'Payment Types',
  PreviousHandoffSelection = 'Previous Handoff Selection',
  PreviousHandoffSelectionSlug = 'Previous Handoff Selection Slug',
  PreviousOrderID = 'Previous Order ID',
  ProductAvailableOptionGroupCount = 'Product Available Option Group Count',
  ProductBasePrice = 'Product Base Price',
  ProductCategory = 'Product Category',
  ProductName = 'Product Name',
  ProductQuantity = 'Product Quantity',
  Response = 'Response',
  SavedANewCard = 'Saved a New Card',
  SearchForLocationDisabled = 'Search for Location Disabled',
  SelectedHandoffMode = 'Selected Handoff Mode',
  SelectedTimeWantedMode = 'Selected Time Wanted Mode',
  SelectedVendorLocation = 'Selected Vendor Location',
  SmartUpsellsDisplayed = 'Smart Upsells Displayed?',
  SignedInViaOloAuthOverlay = 'Signed in via Olo Auth Overlay?',
  StoreCity = 'Store City',
  StoreName = 'Store Name',
  StoreNumber = 'Store Number',
  StorePostalCode = 'Store Postal Code',
  StoreState = 'Store State',
  SupportsArrivalNotifications = 'Supports Arrival Notifications',
  SupportsParkingLocation = 'Supports Parking Location',
  Source = 'Source',
  TimeWantedShown = 'Time Wanted Shown?',
  ToGoOrder = 'To Go order',
  TotalCategories = 'Total Categories',
  UpdatedPhoneNumber = 'Updated Phone Number?',
  UpdatedFirstName = 'Updated First Name?',
  UpdatedLastName = 'Updated Last Name?',
  UpsellGroupName = 'Upsell Group Name',
  UpsellCategories = 'Upsell Categories',
  UpsellItemsInGroupCount = 'Upsell Items in Group Count',
  UpsellPosition = 'Upsell Position',
  UpsellProductIds = 'Upsell Product Ids',
  UpsellReturnedCount = 'Upsell Returned Count',
  UsedASavedCard = 'Used a Saved Card',
  UtmMedium = 'utm_medium',
  VendorId = 'Vendor Id',
  VerifiedNewPhone = 'Verified New Phone Number?',
  ViewedCustomFeesTooltip = 'Viewed Custom Fees Tooltip',
  VisibleLabels = 'Visible Labels',
  WarningShown = 'Warning Shown?',
  ClientTrackerGuid = 'Client Tracker Guid',
  TraceId = 'Olo Trace Id',
  Variant = 'Variant',
  ComponentNameInitiatedModal = 'Component Name Initiated Modal',
  ResponseHeaders = 'Response Headers',
}

export type AnalyticsPropertiesPartial = Partial<Record<AnalyticsProperties, unknown>>;

export const ANALYTICS_TYPES = Object.freeze({
  UA: 'ua',
  GTM: 'gtm',
});

export const TIME_WANTED_ANALYTICS_LABELS = {
  Advance: 'Scheduled',
  Immediate: 'ASAP',
  ManualFire: 'Manual',
};

const NAME_CROSS_CHANNEL = 'crosschannel';

declare global {
  interface Window {
    dataLayer?: UnknownObject[];
    ga?: UniversalAnalytics.ga;
  }
  interface Navigator {
    msDoNotTrack?: string;
  }
  interface External {
    msTrackingProtectionEnabled?: () => boolean;
  }
}

export type Engine = {
  identifier: string;
  isCrossChannel?: boolean;
  trackerName?: string;
  type: string;
};

export type ConfiguredEngine = Engine & { trackerName: string };

export interface EventOptions {
  isLink?: boolean;
  domQuery?: string;
  bucket?: MixpanelBucket | MixpanelBucket[];
}

export enum GAEventCategory {}

export enum GAEventAction {}

export type GAEvent = {
  category: GAEventCategory;
  action: GAEventAction;
  label?: string;
  value?: string;
};

// eslint-disable-next-line ember/no-classic-classes
export default class AnalyticsService extends Service.extend({
  _trackerCount: 0,
  newTrackerNumber() {
    const number = this._trackerCount + 1;
    this._trackerCount = number;
    return number;
  },

  _saveEngine(this: AnalyticsService, engine: Engine) {
    const trackerName = engine.trackerName || `tracker${this.newTrackerNumber()}`;
    const tracker = Object.assign({}, engine, {
      trackerName,
    });
    this.analyticsEngines.push(tracker);

    return trackerName;
  },

  _configureUAInstance(this: AnalyticsService, engine: Engine): Result<ConfiguredEngine, string> {
    if (!this.ga) {
      return errResult(
        'Attempted to configure Google Universal Analytics, but it was not loaded on the page'
      );
    }

    const allowLinker = true;
    const internalName = this.currentChannel?.internalName ?? '';
    const name = engine.isCrossChannel
      ? NAME_CROSS_CHANNEL
      : `perchannel${this.newTrackerNumber()}`;

    const options: UniversalAnalytics.FieldsObject = {
      allowLinker,
      name,
    };

    const currentChannelSettings = this.currentChannel?.settings;
    const fullSiteUrl = currentChannelSettings?.fullSiteUrl;
    if (!engine.isCrossChannel && isSome(fullSiteUrl)) {
      options.cookieDomain = `http://${fullSiteUrl}`;
    }

    if (engine.isCrossChannel) {
      options.sampleRate = ENV.googleAnalyticsSampleRate;
    }

    this.ga('create', engine.identifier, options);
    this.ga(`${name}.set`, 'forceSSL', true);
    this.ga(`${name}.require`, 'linker');
    this.ga(`${name}.linker:autoLink`, new RegExp(internalName, 'gi'));

    return okResult(
      Object.assign({}, engine, {
        trackerName: name,
      })
    );
  },

  _extractConfig(this: AnalyticsService, bootstrapData: BootstrapData): Engine[] {
    const analyticsConfig = [];
    let crossChannelAnalytics = bootstrapData.crossChannelAnalyticsGtm;

    //Delete after 7/1/2023. End of UA
    if (!this.features.flags['multiple-gtm-containers-olo-59870']) {
      crossChannelAnalytics = bootstrapData.crossChannelAnalytics;
    }

    if (isSome(crossChannelAnalytics)) {
      analyticsConfig.push(crossChannelAnalytics);
    }

    const channelAnalytics: Engine[] = isSome(bootstrapData.channel.analytics)
      ? bootstrapData.channel.analytics
      : ([] as Engine[]);

    return analyticsConfig.concat(channelAnalytics);
  },
}) {
  // Service injections
  @service router!: RouterService;
  @service store!: DS.Store;
  @service channel!: ChannelService;
  @service session!: SessionService;
  @service basket!: BasketService;
  @service vendor!: VendorService;
  @service error!: ErrorService;
  @service storage!: StorageService;
  @service bootstrap!: BootstrapService;
  @service globalData!: GlobalDataService;
  @service device!: DeviceService;
  @service globalEvents!: GlobalEventsService;
  @service features!: FeaturesService;
  @service onPremise!: OnPremiseService;
  @service security!: SecurityService;

  // Untracked properties
  analyticsEngines: Engine[] = [];
  mixpanel?: Record<MixpanelBucket, Mixpanel>;

  _wontInitializeMixpanel = false;
  _wontInitializeGoogle = false;
  _gtmInitialized = false;
  _gaQueue: GAEvent[] = [];
  _pageviewQueue: string[] = [];
  _ecommerceQueue: EcommerceOrderModel[] = [];

  @computedSession
  trackedAppOpen?: boolean;

  // Tracked properties
  @tracked _initializedGoogle = false;

  // Getters and setters
  @readOnly('channel.current')
  currentChannel?: ChannelData;

  private get isTearingDown() {
    return this.isDestroying || this.isDestroyed;
  }

  get ga(): UniversalAnalytics.ga {
    return window.ga;
  }

  get dataLayer(): UnknownObject[] | undefined {
    return window.dataLayer;
  }

  set dataLayer(val: UnknownObject[] | undefined) {
    window.dataLayer = val;
  }

  // Lifecycle methods

  // Other methods
  async isServeAppAnalyticsEnabled() {
    // OLO-23553. Replace this with a native capacitor plugin call to invoke the apple tracking consent modal.
    // Since capacitor plugin calls are async, awaiting this promise ensures that the UI will update properly.
    if (
      ENV.isHybrid &&
      Capacitor.getPlatform() === 'ios' &&
      Capacitor.isPluginAvailable('ServeTrackingConsent') &&
      window.Olo.includeAnalytics
    ) {
      const result = (await ServeTrackingConsent.getConsent()).result;
      return result === 'true';
    }

    return true;
  }

  async canSetup() {
    return (
      !this.doNotTrackSet() &&
      isSome(this.router.currentRouteName) &&
      (await this.isServeAppAnalyticsEnabled())
    );
  }

  /** Sets up Google Tag Manager (GTM) and Google Analytics (GA) */
  async setupGoogle(data: BootstrapData): Promise<void> {
    if (!this._initializedGoogle && data && (await this.canSetup())) {
      try {
        const promises: Array<Promise<unknown>> = [];
        if (
          !this.security.currentRouteIsSecure ||
          this.currentChannel?.settings?.allowGtmOnSecureRoutes
        ) {
          promises.push(this._configureEngines(data));
        } else {
          this._wontInitializeGoogle = true;
        }
        if (promises.length) {
          await Promise.all(promises);
        }
      } catch (e) {
        this.flushQueues();
        return Promise.reject(e);
      }
    } else {
      this._wontInitializeGoogle = true;
    }
    this.flushQueues();
    return Promise.resolve();
  }

  async _configureEngines(bootstrapData: BootstrapData): Promise<void> {
    if (this.isTearingDown) {
      return Promise.resolve();
    }

    // Initialize GTM instances first because GTM will also try to load GA
    const engines = this._extractConfig(bootstrapData).sort(engine =>
      engine.type === ANALYTICS_TYPES.GTM ? -1 : 1
    );

    // Setup each analytics service in sequence
    if (this.features.flags['multiple-gtm-containers-olo-59870']) {
      await this._setupGTMEngines(engines.filter(e => e.type === ANALYTICS_TYPES.GTM));
    } else {
      for await (const engine of engines) {
        switch (engine.type) {
          case ANALYTICS_TYPES.UA:
            await this._setupUAEngine(engine);
            break;

          case ANALYTICS_TYPES.GTM:
            await this._setupGTMEngine(engine);
            break;

          default:
            break;
        }
      }
    }

    if (this.isTearingDown) {
      return Promise.resolve();
    }

    this._initializedGoogle = true;

    return Promise.resolve();
  }

  getMixpanel(): Mixpanel {
    if (Ember.testing) {
      // This method can be patched out by integration tests, so use noop methods to avoid loading the real thing
      const mixpanelInstanceStub = {
        track: noop,
        track_links: noop,
        people: { set_once: noop, increment: noop, set: noop },
        register: noop,
        register_once: noop,
        identify: noop,
        reset: noop,
      };
      const mixpanelStub = {
        init: () => mixpanelInstanceStub,
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mixpanelStub as any;
    }
    return mixpanel;
  }

  async setupMixpanel(): Promise<void> {
    if (await this.canSetup()) {
      const mixpanelAnalyticsIds = this.bootstrap.data?.mixpanelAnalyticsIds;
      if (!mixpanelAnalyticsIds) {
        this._wontInitializeMixpanel = true;
        return;
      }

      const keys = mixpanelAnalyticsIds
        ? (Object.keys(mixpanelAnalyticsIds) as MixpanelBucket[])
        : [];
      if (!keys.length) {
        this._wontInitializeMixpanel = true;
        return;
      }

      this.mixpanel = keys.reduce<Record<string, Mixpanel>>((acc, name) => {
        const id = mixpanelAnalyticsIds[name] as string;
        const instance = this.getMixpanel().init(
          id,
          {
            secure_cookie: true,
            debug: true,
            cross_subdomain_cookie: false,
            persistence: 'localStorage',
          },
          `mwc-${name}`
        );

        if (this.session.currentUser) {
          const mixpanelId = this.session.mixpanelUniqueId || this.session.currentUser.id;
          instance.people.set({
            'User ID': mixpanelId,
          });
          instance.identify(mixpanelId);
        }
        instance.people.increment('Page Views');
        instance.register({
          [AnalyticsProperties.ClientPlatform]: 'MobileWeb',
          [AnalyticsProperties.Channel]: this.currentChannel?.internalName,
          [AnalyticsProperties.IsHybrid]: ENV.isHybrid ? true : false,
        });

        acc[name] = instance;

        return acc;
      }, {});

      if (!this.trackedAppOpen) {
        this.trackedAppOpen = true;
        this.mixpanelTrack('default', AnalyticsEvents.AppOpen);
      }

      if (this.device.isHybrid && isEmpty(this.storage.hybridEverOpened)) {
        this.storage.hybridEverOpened = true;
        this.mixpanelTrack('default', AnalyticsEvents.FirstAppOpen);
        this.globalEvents.trigger(GlobalEventName.AppFirstOpen);
      }
    } else {
      this._wontInitializeMixpanel = true;
      return;
    }
  }

  bootUpUAEngine(): Promise<void> {
    return new Promise((res, rej) => {
      /* eslint-disable */
      (function (i, s, o, g, r, a, m) {
        // @ts-ignore
        i.GoogleAnalyticsObject = r;
        // @ts-ignore
        (i[r] =
          // @ts-ignore
          i[r] ||
          function () {
            // @ts-ignore
            (i[r].q = i[r].q || []).push(arguments);
          }),
          // @ts-ignore
          (i[r].l = Number(new Date())); // `Number` instead of `1 *` for TypeScript not to yell.
        // @ts-ignore
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        // @ts-ignore
        a.async = 1;
        // @ts-ignore
        a.src = g;
        // @ts-ignore
        a.onload = res;
        // @ts-ignore
        a.onerror = rej;
        // @ts-ignore
        m.parentNode.insertBefore(a, m);
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
      /* eslint-enable */
    });
  }

  bootUpGTMInstance(engineIdentifier: string): Promise<void> {
    return new Promise((res, rej) => {
      /* eslint-disable */ // Google Tag Manager standard setup code.
      (function (w, d, s, l, i) {
        // @ts-ignore -- this is GA-generated stuff
        w[l] = w[l] || [];
        // @ts-ignore -- this is GA-generated stuff
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        let f = d.getElementsByTagName(s)[0],
          j = d.createElement(s) as HTMLScriptElement,
          dl = l != 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        // Wait a frame to be done because GTM will load GA after it has been initialized
        // and if we don't delay then any non-GTM GA inits will happen _before_ the GTM GA inits
        // which will lead to multiple loads of `analytics.js`. Oof.
        j.onload = () => setTimeout(res, 0);
        j.onerror = rej;
        // @ts-ignore -- this is GA-generated stuff
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', engineIdentifier);
      /* eslint-enable */
    });
  }

  // NOTE: this assumes there is only one Google Tag Manager instance.
  async _setupGTMEngine(engine: Engine): Promise<void> {
    if (this.isTearingDown) {
      return Promise.resolve();
    }

    this.dataLayer = this.dataLayer || [];
    if (!this._gtmInitialized) {
      this._gtmInitialized = true;
      await this.bootUpGTMInstance(engine.identifier);
    }

    if (this.isTearingDown) {
      return Promise.resolve();
    }

    this._saveEngine(engine);

    this.updateDataLayer();

    // Throttle responding to data changes so we aren't spamming `dataLayer`.
    // GTM (which uses dataLayer for its variables and events) only holds ~250
    // layers at a time, so it's better to update in batches. Also, globalData
    // can be a little noisy when the app is booting up, so better to batch then.
    // eslint-disable-next-line ember/no-observers
    this.globalData.addObserver(
      'data',
      this,
      throttle(this.updateDataLayer, 100, { leading: true, trailing: true })
    );

    return Promise.resolve();
  }

  async _setupGTMEngines(engines: Engine[]): Promise<void> {
    if (this.isTearingDown) {
      return Promise.resolve();
    }

    this.dataLayer = this.dataLayer || [];
    if (!this._gtmInitialized) {
      this._gtmInitialized = true;
      engines.forEach(async e => await this.bootUpGTMInstance(e.identifier));
    }

    if (this.isTearingDown) {
      return Promise.resolve();
    }

    engines.forEach(e => this._saveEngine(e));

    this.updateDataLayer();

    // Throttle responding to data changes so we aren't spamming `dataLayer`.
    // GTM (which uses dataLayer for its variables and events) only holds ~250
    // layers at a time, so it's better to update in batches. Also, globalData
    // can be a little noisy when the app is booting up, so better to batch then.
    // eslint-disable-next-line ember/no-observers
    this.globalData.addObserver(
      'data',
      this,
      throttle(this.updateDataLayer, 100, { leading: true, trailing: true })
    );

    return Promise.resolve();
  }

  async _setupUAEngine(engine: Engine): Promise<void> {
    if (isNone(this.ga)) {
      await this.bootUpUAEngine();
    }

    if (this.isTearingDown) {
      return Promise.resolve();
    }

    const configuredEngine = this._configureUAInstance(engine);
    if (isOk(configuredEngine)) {
      this._saveEngine(configuredEngine.value);
    } else {
      this.error.reportError(configuredEngine.err);
    }

    return Promise.resolve();
  }

  doNotTrackSet(): boolean {
    return navigator.doNotTrack === 'yes' || navigator.doNotTrack === '1';
  }

  trackEvent(
    eventName: EventName,
    customProperties: () => AnalyticsPropertiesPartial = () => ({}),
    opts: EventOptions = {}
  ): Promise<void> {
    return new Promise((res, rej) => {
      if (this.isDestroying) {
        return;
      }

      if (this._wontInitializeMixpanel) {
        res();
        return;
      }

      let properties: UnknownObject | undefined;
      try {
        const { isLink, domQuery, bucket = 'default' } = opts;
        const buckets = typeof bucket === 'string' ? [bucket] : bucket;
        properties = this.getTrackedEventProperties(customProperties());

        if (this.hasCircularReference(properties)) {
          throw new Error(`Circular object could not be serialized for ` + eventName);
        }

        if (buckets.includes('all')) {
          if (isLink) {
            this.mixpanelTrackLinksAll(domQuery!, eventName, properties);
          } else {
            this.mixpanelTrackAll(eventName, properties);
          }
        } else {
          buckets.forEach((b: MixpanelBucket) => {
            if (isLink) {
              this.mixpanelTrackLinks(b, domQuery!, eventName, properties);
            } else {
              this.mixpanelTrack(b, eventName, properties);
            }
          });
        }
        res();
      } catch (e) {
        this.error.sendExternalError(e);
        rej(e);
      }
    });
  }

  getTrackedEventProperties(customProperties: UnknownObject): UnknownObject {
    // formatted to match Ghost, see PLAT-20752
    function getTimeProperties() {
      return {
        [AnalyticsProperties.CurrentUserTime]: dayjs().format('HH:mm:ss'),
        [AnalyticsProperties.CurrentUserTimeZone]: dayjs().format('ZZ'),
      };
    }

    function dashToSpace(source: UnknownObject) {
      return Object.keys(source).reduce(
        (prev, curr) => ({ ...prev, [curr.replace(/-/g, ' ')]: source[curr] }),
        {}
      );
    }

    const getABTestFlags = () => {
      const allFlags = this.features.allFlags;
      const abKeys = Object.keys(allFlags).filter(k => k.startsWith(AB_TEST_FLAG_PREFIX));
      return pick(allFlags, abKeys);
    };

    let properties = {};

    properties = {
      ...dashToSpace(customProperties),
      ...getTimeProperties(),
      [AnalyticsProperties.ABTestFlags]: getABTestFlags(),
      [AnalyticsProperties.AppVersion]: window.Olo.appVersion,
      [AnalyticsProperties.PageTitle]: document.title,
      [AnalyticsProperties.PageName]: routeToPageName(this.router.currentRouteName),
      [AnalyticsProperties.HasOloAuth]: !!this.session.oloAuthProviderSlug,
      [AnalyticsProperties.IsTranslated]: this.bootstrap.isTranslated,
      [AnalyticsProperties.IsGuest]: !this.session.isLoggedIn,
      [AnalyticsProperties.IsOloChannelLogin]:
        this.session.isLoggedIn &&
        !this.session.isOloAuthLogin &&
        !this.bootstrap.data?.loginProviderName,
      [AnalyticsProperties.IsOloAuth]: this.session.isOloAuthLogin,
      [AnalyticsProperties.IsSSO]: this.session.isSsoLogin,
      [AnalyticsProperties.LoginProvider]: this.session.loginProviderName,
      [AnalyticsProperties.Channel]: this.currentChannel?.internalName,
      [AnalyticsProperties.ChannelId]: this.currentChannel?.id,
      [AnalyticsProperties.IsPayAtTable]: this.onPremise.isPayAtTable,
    };

    const basket = this.basket.basket ?? this.storage.orderSubmission?.basket;
    if (basket) {
      const basketProducts =
        'belongsTo' in basket ? basket.basketProducts.toArray() : basket.basketProducts;
      const products = basketProducts.map(product => product.productName);
      const taxTotal = basket.taxes
        ? basket.taxes.reduce((currentValue, tax) => currentValue + tax.totalTax, 0)
        : 0;

      const safeFixed = (value?: number) => (isSome(value) ? value.toFixed(2) : undefined);

      properties = {
        ...properties,
        [AnalyticsProperties.BasketID]: basket.guid,
        [AnalyticsProperties.BasketProducts]: products,
        [AnalyticsProperties.BasketProductQuantity]: basketProducts.reduce(
          (currentValue, product) => currentValue + product.quantity,
          0
        ),
        [AnalyticsProperties.BasketHasDiscount]: basket.vendorDiscount > 0,
        [AnalyticsProperties.BasketSubtotal]: safeFixed(basket.subTotal),
        [AnalyticsProperties.BasketDiscount]: safeFixed(basket.vendorDiscount),
        [AnalyticsProperties.BasketTax]: safeFixed(taxTotal),
        [AnalyticsProperties.BasketDeliveryCharge]: safeFixed(basket.deliveryCharge),
        [AnalyticsProperties.BasketTotal]: safeFixed(basket.total),
        [AnalyticsProperties.BasketTimeWantedMode]:
          TIME_WANTED_ANALYTICS_LABELS[basket.timeWantedType],
        [AnalyticsProperties.BasketHandoffMode]: basket.handoffMode,
        [AnalyticsProperties.BasketOnPremiseExperience]: this.onPremise.experienceType,
        [AnalyticsProperties.BasketOnPremiseTable]: this.onPremise.tablePosRef,
        [AnalyticsProperties.BasketHasSingleUseProducts]: basket.containsSingleUseItems,
        [AnalyticsProperties.BasketLeadTimeEstimate]: (basket as BasketModel)?.leadTimeEstimate,
      };
    }

    let vendor;
    if (basket && 'belongsTo' in basket) {
      vendor = basket.belongsTo('vendor').value() as Vendor | undefined;
    } else {
      vendor = basket?.vendor;
    }
    vendor = vendor ?? this.vendor.vendor;

    if (vendor) {
      properties = {
        ...properties,
        [AnalyticsProperties.StoreName]: vendor.name,
        [AnalyticsProperties.StoreCity]: vendor.address.city,
        [AnalyticsProperties.StoreState]: vendor.address.state,
        [AnalyticsProperties.StorePostalCode]: vendor.address.postalCode,
        [AnalyticsProperties.StoreNumber]: vendor.externalReference,
        [AnalyticsProperties.IsOloPay]: vendor.settings && !!vendor.settings.isOloPay,
        [AnalyticsProperties.IsDynamicPricingEnabled]:
          vendor.settings && !!vendor.settings.supportsDynamicPricing,
      };
    }
    return properties;
  }

  trackGAEvent(event: GAEvent): void {
    if (!this._initializedGoogle && !this._wontInitializeGoogle) {
      this._gaQueue.push(event);
      return;
    }
    if (this.ga) {
      this.ga(`${NAME_CROSS_CHANNEL}.send`, {
        hitType: 'event',
        eventCategory: event.category,
        eventAction: event.action,
        eventLabel: event.label,
        eventValue: event.value,
      });
    }
  }

  trackPageviewGoogle(page: string): void {
    if (!this._initializedGoogle && !this._wontInitializeGoogle) {
      this._pageviewQueue.push(page);
      return;
    }

    this.analyticsEngines.forEach(engine => {
      switch (engine.type) {
        case ANALYTICS_TYPES.UA: {
          if (this.ga) {
            this.ga(`${engine.trackerName}.send`, 'pageview', page);
          }
          break;
        }
        case ANALYTICS_TYPES.GTM:
          if (this.dataLayer) {
            /**
             * @deprecated as of 10/2020 - remove this when we can verify no brands are using it
             */
            this.dataLayer.push({
              event: 'vpv',
              virtualPagePath: page,
            });
          }
          break;

        default:
          break;
      }
    });
  }

  trackPageview(page: string): void {
    this.trackEvent(AnalyticsEvents.ViewPage);
    this.trackPageviewGoogle(page);
  }

  trackEcommerce(order: EcommerceOrderModel): void {
    if (!this._initializedGoogle && !this._wontInitializeGoogle) {
      this._ecommerceQueue.push(order);
      return;
    }
    this.analyticsEngines.forEach(engine => {
      switch (engine.type) {
        case ANALYTICS_TYPES.UA:
          if (this.ga) {
            const ga = this.ga;
            ga(`${engine.trackerName}.require`, 'ecommerce');
            ga(`${engine.trackerName}.ecommerce:addTransaction`, {
              id: order.transactionId,
              affiliation: order.transactionAffiliation,
              revenue: order.transactionTotal,
              shipping: order.transactionShipping,
              tax: order.transactionTax,
              currency: order.transactionCurrency,
            });

            order.transactionProducts.forEach(p =>
              ga(`${engine.trackerName}.ecommerce:addItem`, p)
            );

            ga(`${engine.trackerName}.ecommerce:send`);
          }
          break;

        case ANALYTICS_TYPES.GTM:
          if (this.dataLayer) {
            this.dataLayer.push(order);
          }
          break;

        default:
          break;
      }
    });
  }

  /**
   * @deprecated since 10/2020 in favor of using global events
   */
  private updateDataLayer() {
    if (this.isTearingDown) {
      return;
    }

    this.dataLayer?.push(this.globalData.data as UnknownObject);
  }

  /*
   * Flush any queued events that were fired before we initialized
   */
  private flushQueues(): void {
    this.flushGAQueues();
  }

  private flushGAQueues(): void {
    this._gaQueue.forEach(event => this.trackGAEvent(event));
    this._gaQueue = [];
    this._pageviewQueue.forEach(event => this.trackPageviewGoogle(event));
    this._pageviewQueue = [];
    this._ecommerceQueue.forEach(event => this.trackEcommerce(event));
    this._ecommerceQueue = [];
  }

  private mixpanelTrack(
    bucket: MixpanelBucket,
    eventName: EventName,
    properties?: UnknownObject
  ): void {
    if (!this.mixpanel) {
      return;
    }
    if (!this.mixpanel[bucket]) {
      this.error.sendExternalError(new Error(`Mixpanel bucket ${bucket} not found`));
      return;
    }
    // Don't track Olo Auth if there is no Olo Auth provider
    if (bucket === 'olo-auth' && !this.bootstrap.data?.hasOloAuthProvider) {
      return;
    }
    this.mixpanel[bucket].track(eventName, properties);
  }

  private hasCircularReference(obj: AnyObject, set = new WeakSet()): any {
    if (isNone(obj) || typeof obj !== 'object') {
      return false;
    }

    if (set.has(obj)) {
      return true;
    }

    set.add(obj);

    const hasCircularChildren = Object.keys(obj).some(key =>
      this.hasCircularReference(obj[key], set)
    );
    if (hasCircularChildren) {
      return true;
    }

    set.delete(obj);
    return false;
  }

  private mixpanelTrackAll(eventName: EventName, properties?: UnknownObject): void {
    if (!this.mixpanel) {
      return;
    }
    Object.keys(this.mixpanel).forEach((bucket: MixpanelBucket) =>
      this.mixpanelTrack(bucket, eventName, properties)
    );
  }

  private mixpanelTrackLinks(
    bucket: MixpanelBucket,
    domQuery: string | undefined,
    eventName: EventName,
    properties?: UnknownObject
  ): void {
    if (!this.mixpanel) {
      return;
    }
    if (!this.mixpanel[bucket]) {
      this.error.sendExternalError(new Error(`Mixpanel bucket ${bucket} not found`));
      return;
    }
    this.mixpanel[bucket].track_links(domQuery!, eventName, properties);
  }

  private mixpanelTrackLinksAll(
    domQuery: string | undefined,
    eventName: EventName,
    properties?: UnknownObject
  ): void {
    if (!this.mixpanel) {
      return;
    }
    Object.keys(this.mixpanel).forEach((bucket: MixpanelBucket) =>
      this.mixpanelTrackLinks(bucket, domQuery, eventName, properties)
    );
  }

  public mixpanelReset() {
    if (!this.mixpanel) {
      return;
    }

    Object.keys(this.mixpanel).forEach((bucket: MixpanelBucket) => {
      this.mixpanel?.[bucket].reset();
    });
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    analytics: AnalyticsService;
  }
}
